<template>
  <v-container>
      <v-card
          class="mx-auto text-center pa-5"
          max-width="600">
        <v-card-title>
          Ajouter un document
        </v-card-title>
        <v-form
            ref="form"
            v-model="valid"
            lazy-validation
        >
          <v-text-field
              v-model="file.name"
              :counter="100"
              label="Nom"
              required
          ></v-text-field>

          <v-text-field
              v-model="file.description"
              label="Description"
          ></v-text-field>

          <v-select
              v-model="file.category"
              :items="items"
              :rules="[v => !!v || 'Item is required']"
              label="Catégorie"
              required
          ></v-select>

          <v-file-input
              v-model="doc"
              label="Document"
              @change="uploadFile"
          ></v-file-input>

          <v-checkbox
              v-model="checkbox"
              :rules="[v => !!v || 'You must agree to continue!']"
              label="Êtes-vous d'accord ?"
              required
          ></v-checkbox>

          <v-btn
              :disabled="!valid"
              color="success"
              class="mr-4"
              @click="addFile"
          >
            Ajouter
          </v-btn>

          <v-btn
              color="error"
              class="mr-4"
              @click="reset"
          >
            Annuler
          </v-btn>

        </v-form>
      </v-card>
      <v-card>
        <v-simple-table>
          <template v-slot:default>
            <thead> Listes des Documents
            <tr>
              <th class="text-left">
                Name
              </th>
              <th class="text-left">
                Description
              </th>
              <th class="text-left">
                Category
              </th>
              <th class="text-left">
                Document
              </th>
              <th class="text-left">
                Actions
              </th>
            </tr>
            </thead>
            <tbody>
            <tr
                v-for="file in files" :key="file.name"
            >
              <td>{{ file.name }}</td>
              <td>{{ file.description }}</td>
              <td>{{ file.category }}</td>
              <td><v-img :src="file.image" max-height="50" max-width="50"/></td>
              <td><a :src="file.fileItem">Download</a></td>
              <td>
                <v-btn color="teal" outlined small rounded ><v-icon>mdi-loupe</v-icon></v-btn> |
                <v-btn color="yellow" outlined small rounded @click="updateFile(file.name)"><v-icon>mdi-swap-horizontal</v-icon></v-btn> |
                <v-btn color="red" outlined small rounded @click="deleteFile(file.name)" ><v-icon>mdi-delete</v-icon></v-btn>
                <v-dialog
                    v-model="dialog"
                    width="500"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        elevation="2"
                        fab
                        v-bind="attrs"
                        v-on="on"
                        @click="updateFile(file.name)"
                    >
                      <v-icon>
                        fa-plus
                      </v-icon>
                    </v-btn>
                  </template>

                  <v-card>
                    <v-form>
                      <v-text-field
                          v-model="file.name"
                          label="Name"
                          required
                      ></v-text-field>

                      <v-text-field
                          v-model="file.description"
                          label="Description"
                      ></v-text-field>

                      <v-select
                          v-model="file.category"
                          :items="items"
                          :rules="[v => !!v || 'Item is required']"
                          label="Item"
                          required
                      ></v-select>

                      <v-file-input
                          v-model="file.fileItem"
                          label="Document"
                          @click="uploadFile"
                      ></v-file-input>

                      <v-checkbox
                          v-model="checkbox"
                          :rules="[v => !!v || 'You must agree to continue!']"
                          label="Do you agree?"
                          required
                      ></v-checkbox>

                      <v-btn
                          :disabled="!valid"
                          color="success"
                          class="mr-4"
                      >
                        Ajouter
                      </v-btn>

                      <v-btn
                          color="error"
                          class="mr-4"
                          @click="reset"
                      >
                        Annuler
                      </v-btn>
                    </v-form>
                  </v-card>
                </v-dialog>

              </td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
  </v-container>
</template>

<script>
// import { collection, addDoc, getDocs, query } from "firebase/firestore";
import {db, firebase} from "../main";
export default {
  name: "Files",
  data: () => ({
    valid: true,
    dialog: false,
    items: [
      'Communication',
      'Ressources Humaines',
      'Process & Formation',
    ],
    doc:'',
    files:[],
    file:{
      name: '',
      description: '',
      category: null,
      image:"https://findicons.com/files/icons/312/folder/512/documents.png",
      fileItem: null
    },
    name: '',
    description: '',
    category: null,
    image:"https://findicons.com/files/icons/312/folder/512/documents.png",
    fileItem: null,
    uploadTask: null,
    uploadState: null,
    activeItem:null,
    checkbox: false,
  }),
  firestore(){
      return{
        files: db.collection("files"),
      }
  },
  methods: {
    async getFiles(){
      await db.collection("files").get().then((querySnapshot) => {
        const files = []
          querySnapshot.forEach((doc) => {

            files.push(doc.data());
          })
        this.files = files
        });
      return this.files
    },
    async addFile () {
      await db.collection("files").add(
          {
            name: this.file.name,
            description: this.file.description,
            category: this.file.category,
            image: "https://findicons.com/files/icons/312/folder/512/documents.png",
            fileItem: this.uploadFile()
          }
      )
      .then(function (fileRef) {
        console.log("Document written with ID: ", fileRef.id);
        this.getFiles()
      })
      .catch(function (error) {
        console.error("Error adding document: ", error);
      })
    },
    updateFile(){
      db.collection("files")
          .where('name', '==', this.file.name).get()
          .then(querySnapshot => {
            querySnapshot.forEach(doc => {
              doc.ref.set({
                name: this.name,
                description: this.description,
                category: this.category,
                image: "https://findicons.com/files/icons/312/folder/512/documents.png",
                fileItem: this.uploadFile()
              }).then(this.getProducts)
            })
          })
    },
    deleteFile(name){
      if(confirm("Are you sure ?")){
        db.collection("files")
            .where('name', '==', name).get()
            .then(querySnapshot => {
              querySnapshot.forEach(doc => {
                doc.ref.delete().then(this.getFiles)
              })
            })
      }
    },
    uploadFile(){
      let doc = this.doc
      let storageRef = firebase.storage().ref("documentations/"+doc.name)
      this.uploadTask = storageRef.put(doc);
      this.uploadTask.on("state_changed", () =>{console.log("next step")
        this.uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) =>{
          console.log("File available at", downloadURL);
          this.file.fileItem = downloadURL
        })
      })
      return this.file.fileItem
    },
    validate () {
      this.$refs.form.validate()
    },
    reset () {
      this.$refs.form.remove()
    },
  },
  created(){
    this.getFiles();
  }
}
</script>

<style scoped>

</style>